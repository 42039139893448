import { GatsbyImage } from "gatsby-plugin-image";
import {
  CalculateFontSize,
  CalculateFromHeight,
  CalculateFromWidth
} from "styled/base/SizeFunctions";
import { CallToActionArticleDescriptionFont } from "styled/common/font-styles/CommonFonts";
import { Background, NonSelectable } from "styled/common/Utils";
import styled from "styled-components";

export const ArticleCtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  ${CalculateFromHeight("margin-top", { mobile: 17, tablet: 15, desktop: 13 })};
  ${CalculateFromHeight("margin-bottom", { mobile: 17, tablet: 15, desktop: 13 })};
  ${CalculateFromWidth("max-width", { mobile: 87.5, tablet: 88.5 })};
  ${CalculateFromWidth("width", { mobile: 90, tablet: 90 })};

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
`;

export const ArticleCtaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-width: 560px;
  }
`;

export const ArticleCtaDescription = styled.p`
  position: relative;
  ${CalculateFromHeight("margin-top", { mobile: 3, tablet: 4, desktop: 4.5 })}
  ${CalculateFromHeight("padding-right", { desktop: 3 })}
  ${CallToActionArticleDescriptionFont}
  ${CalculateFontSize("font-size", { mobile: 1.6 })}
`;

export const ArticleCtaImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  order: -1;
  ${CalculateFromHeight("margin-bottom", { mobile: 3, tablet: 4 })};

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    order: 1;
  }
`;

export const ArticleCtaImage = styled(GatsbyImage)`
  position: relative;
  max-width: 660px;
  width: 100%;
  height: 100%;

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-width: 580px;
  }

  ${NonSelectable};

  & > img {
    ${Background};
  }
`;

export const ArticleCtaButtonWrapper = styled.div`
  position: relative;
  display: flex;
  ${CalculateFromHeight("margin-top", { mobile: 21, tablet: 12, desktop: 12 })};
  margin-bottom: 50px;
  max-width: 356px;

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-width: 336px;
    margin-left: 20px;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    max-width: 316px;
  }
`;
