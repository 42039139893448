import { GatsbyImage } from "gatsby-plugin-image";
import ListStyleImage from "media/images/icons/other/article-list-icon.svg";
import {
  CalculateFontSize,
  CalculateFromHeight,
  CalculateFromWidth
} from "styled/base/SizeFunctions";
import {
  ArticleContentFont,
  ArticleSectionHeadingFont,
  ArticleSectionTextFont,
  ArticleSubsectionHeadingFont,
  ArticleTitleFont
} from "styled/common/font-styles/ArticleFonts";
import { NonSelectable, SizeFillSpaceMixin, StaticElement } from "styled/common/Utils";
import styled from "styled-components";

export const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${SizeFillSpaceMixin}
`;

export const ArticleContentWrapper = styled.article`
  position: relative;
  color: ${({ theme: { colors } }) => colors.black};
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5, desktop: 5 })}
  ${CalculateFromHeight("margin-bottom", { desktop: 11 })}
`;
export const ArticleContentContainer = styled.div`
  ${ArticleContentFont}
  ${CalculateFromHeight("margin-top", { mobile: 11, tablet: 11, desktop: 11 })};
  ${CalculateFromWidth("max-width", { mobile: 87.5, tablet: 88.5, desktop: 50 })};
  ${CalculateFromWidth("width", { mobile: 90, tablet: 90, desktop: 90 })};
  margin-left: auto;
  margin-right: auto;

  & a span {
    cursor: default;
  }

  & .article-title {
    ${ArticleTitleFont}
    ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5, desktop: 5 })}
  }

  & .article-section-heading {
    ${ArticleSectionHeadingFont}
    ${CalculateFromHeight("margin-top", { mobile: 6, tablet: 6, desktop: 6 })}
    ${CalculateFromHeight("margin-bottom", { mobile: 2.5, tablet: 2.5, desktop: 2.5 })}
  }

  & .article-subsection-heading {
    ${ArticleSubsectionHeadingFont}
    ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5, desktop: 5 })};
    ${CalculateFromHeight("margin-bottom", { mobile: 1.9, tablet: 1.9, desktop: 1.9 })};
  }

  & .article-section-text {
    ${ArticleSectionTextFont}
    ${CalculateFromHeight("margin-bottom", { mobile: 1.5, tablet: 1.5, desktop: 1.5 })}
  }

  & .article-section-emphasis {
    ${({ theme: { fonts } }) => fonts.blog}
    font-weight: 400;
    ${CalculateFontSize("font-size", { mobile: 2.1, tablet: 1.7, desktop: 1.55 })}
    ${CalculateFontSize("line-height", { mobile: 3, tablet: 3, desktop: 1.5 })}
  }

  & .article-link {
    color: #6729e9;
    text-decoration: underline;
  }

  & .article-list-icon {
    ${ArticleSectionTextFont}
    list-style-image: url(${ListStyleImage});
    & li {
      ${CalculateFromWidth("padding-left", { mobile: 4, tablet: 2, desktop: 2 })};
      ${CalculateFromWidth("padding-bottom", { mobile: 4, tablet: 1.5, desktop: 1.5 })};
    }
  }
`;
export const ArticleTitle = styled.h1`
  ${ArticleTitleFont}
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5, desktop: 5 })};
`;
export const ArticleIllustrationWrapper = styled.div`
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding-top: 66%;
  height: 0;
  overflow: hidden;
  position: relative;
  display: flex;

  margin-left: auto;
  margin-right: auto;

  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5, desktop: 5 })};
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5, desktop: 5 })};
`;
export const ArticleIllustration = styled.div`
  ${StaticElement}
  ${SizeFillSpaceMixin}
`;

export const ArticleIllustrationPhoto = styled(GatsbyImage)`
  ${NonSelectable}
  ${SizeFillSpaceMixin}
`;
export const ArticleLineAboveRecommendedArticles = styled.div`
  position: relative;
  border-bottom-style: solid;
  border-bottom-color: #e5e5e5;
  border-bottom-width: 1px;

  ${CalculateFromHeight("margin-top", { mobile: 1, tablet: 1, desktop: 1 })};
  ${CalculateFromWidth("margin-left", { mobile: 4, tablet: 4, desktop: 4 })};
  ${CalculateFromWidth("margin-bottom", { mobile: 4, tablet: 4, desktop: 4 })};
  ${CalculateFromWidth("margin-right", { mobile: 3, tablet: 3, desktop: 3 })};
  ${CalculateFromHeight("margin-top", { tablet: 5, mobile: 10 })};
  ${CalculateFromHeight("margin-bottom", { tablet: 5, mobile: 15 })};
`;
