import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ArticleNewsletterDescriptionFont,
  ArticleNewsletterInputFont
} from "styled/common/font-styles/ArticleFonts";
import { TextBlack } from "styled/common/Utils";
import styled from "styled-components";

export const ArticleNewsletterWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  z-index: 1;
  display: grid;
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(18, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  ${CalculateFromHeight("margin-top", { mobile: 5 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 15, tablet: 5, desktop: 13 })}
  ${CalculateFromHeight("height", { mobile: 160, tablet: 90, desktop: 90 })}
  width: 100%;
`;

export const ArticleNewsletterForm = styled.form`
  display: grid;

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 7 / span 5;
    grid-column: 1 / span 12;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(8, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 7 / span 5;
    grid-column: 1 / span 12;
    grid-template-rows: repeat(9, 1fr);
    grid-template-columns: repeat(9, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 9 / span 10;
    grid-column: 1 / span 12;
    grid-template-rows: repeat(9, 1fr);
    grid-template-columns: repeat(9, 1fr);
  }
  ${CalculateFromWidth("margin-left", { mobile: 2, tablet: 2, desktop: 2 })}
  ${CalculateFromWidth("margin-right", { mobile: 2, tablet: 2, desktop: 2 })}
`;

export const ArticleNewsletterDescription = styled.p`
  ${TextBlack}
  ${ArticleNewsletterDescriptionFont}
  grid-row: 6 / span 2;
  grid-column: 2 / span 4;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 6 / span 2;
    grid-column: 2 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 6 / span 2;
    grid-column: 1 / span 12;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 6 / span 4;
    grid-column: 1 / span 12;
  }
  ${CalculateFromWidth("margin-left", { mobile: 3, tablet: 2 })}
  ${CalculateFromWidth("margin-right", { mobile: 2 })}
  ${CalculateFromHeight("margin-top", { mobile: 1 })}
`;

export const ArticleNewsletterButton = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 4 / span 3;
    grid-column: 6 / span 2;
    margin-right: 0;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 7 / span 3;
    grid-column: 4 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 3 / span 6;
    grid-column: 3 / span 5;
  }
  ${CalculateFromHeight("margin", { mobile: 4 })}
  ${CalculateFromHeight("margin-top", { mobile: 8, desktop: 6 })}
  ${CalculateFromHeight("margin-bottom", { tablet: 2 })}
`;
export const ArticleNewsletterInput = styled.input`
  border: none;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  border-bottom-color: black;
  outline: none;
  background-color: transparent;
  color: black;
  ${ArticleNewsletterInputFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 3 / span 5;
    grid-row: 2 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 2 / span 3;
    grid-column: 1 / span 9;
    border-bottom-style: double;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 1 / span 4;
    grid-column: 1 / span 9;
    border-bottom-style: double;
  }
  ${CalculateFromHeight("margin-top", { mobile: 8, tablet: 4, desktop: 3 })}
  ${CalculateFromHeight("height", { mobile: 6, tablet: 6, desktop: 6 })}
  ${CalculateFromWidth("margin-left", { desktop: 0.9 })}
`;

export const ArticleNewsletterInformation = styled.span`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 4 / span 2;
    grid-column: 3 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 4 / span 2;
    grid-column: 1 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 2 / span 2;
    grid-column: 1 / span 12;
  }
  ${CalculateFromHeight("margin-top", { mobile: 8, tablet: 6, desktop: 3 })}
  ${CalculateFromHeight("margin-left", { desktop: 0.9 })}
`;
