import React from "react";

import {
  ArticlePreviewSectionHeader,
  ArticlePreviewSectionTitle,
  ArticlePreviewSectionWrapper
} from "components/pages/article/ArticleFeaturedSection/ArticlesFeaturedSection.styled";
import FeaturedArticles from "components/pages/article/ArticlesFeatured/ArticlesFeatured";
import type { ArticlePreviewData } from "components/pages/blog-common/ArticlePreview/ArticlePreview";
import RectangularButton from "components/pages/blog-common/RectangularButton/RectangularButton";
import type { ArticleData } from "pages/blog/article";
import Translate from "utils/translation/translation";

interface FeaturedArticlesSectionProps {
  article: {
    markdownRemark: ArticleData;
  };
  allArticlePreview: ArticlePreviewData[];
}

export const FeaturedArticlesSection = ({
  article,
  allArticlePreview
}: FeaturedArticlesSectionProps) => {
  return (
    <ArticlePreviewSectionWrapper>
      <ArticlePreviewSectionHeader>
        <ArticlePreviewSectionTitle>{Translate("More_From_Our_Blog")}</ArticlePreviewSectionTitle>
        <RectangularButton
          path={"/blog"}
          text={Translate("Button_Back_Post")}
          variant="back-post"
          direction="left"
          isArticlePage
        />
      </ArticlePreviewSectionHeader>
      <FeaturedArticles
        articleList={allArticlePreview}
        tagsList={article.markdownRemark.frontmatter.tags}
        articleTitle={article.markdownRemark.frontmatter.title}
      />
    </ArticlePreviewSectionWrapper>
  );
};

export default FeaturedArticlesSection;
