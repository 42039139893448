import React, { createElement, RefObject, useMemo, useRef } from "react";

import ArticleAuthor from "components/pages/article/ArticleAuthor/ArticleAuthor";
import {
  ArticleContentContainer,
  ArticleContentWrapper,
  ArticleIllustration,
  ArticleIllustrationPhoto,
  ArticleIllustrationWrapper,
  ArticleLineAboveRecommendedArticles,
  ArticleTitle,
  ArticleWrapper
} from "components/pages/article/ArticleContent/ArticleContent.styled";
import ArticleCustomImage from "components/pages/article/ArticleCustomImage";
import FeaturedArticlesSection from "components/pages/article/ArticleFeaturedSection/ArticlesFeaturedSection";
import ArticleNewsletter from "components/pages/article/ArticlesNewsletter/ArticleNewsletter";
import type { ArticlePreviewData } from "components/pages/blog-common/ArticlePreview/ArticlePreview";
import { graphql, useStaticQuery } from "gatsby";
import { useChangeScrollbarColor } from "hooks/useChangeScrollbarColor";
import { useRect } from "hooks/useRect";
import type { ArticleData } from "pages/blog/article";
import rehype2react from "rehype-react";
import { Plugin, unified } from "unified";

import ArticleCTA from "../ArticleCTA/ArticleCTA";

interface ArticleContentProps {
  article: {
    markdownRemark: ArticleData;
  };
  articleContentRef: RefObject<HTMLDivElement>;
}

export const ArticleContent = ({ article, articleContentRef }: ArticleContentProps) => {
  const data = useStaticQuery(query);
  const allArticlePreview: ArticlePreviewData[] = data.allMarkdownRemark.edges;
  const articleWrapperRef = useRef<HTMLDivElement>(null);
  const articleWrapperRect = useRect(articleWrapperRef);

  useChangeScrollbarColor({ ref: articleWrapperRef, offset: articleWrapperRect.top });

  const renderAst = useMemo(() => {
    return unified().use(rehype2react as unknown as Plugin, {
      createElement: createElement,
      components: {
        articleCustomImage: props => <ArticleCustomImage {...props} />
      }
    }).stringify;
  }, []);

  return (
    <ArticleWrapper ref={articleWrapperRef}>
      <ArticleContentWrapper>
        <ArticleContentContainer ref={articleContentRef}>
          <>
            <ArticleTitle>{article.markdownRemark.frontmatter.title}</ArticleTitle>
            <ArticleAuthor markdown={article.markdownRemark} />
            <ArticleIllustrationWrapper>
              <ArticleIllustration>
                <ArticleIllustrationPhoto
                  image={article.markdownRemark.frontmatter.image.childImageSharp.gatsbyImageData}
                  imgStyle={{ objectFit: "contain" }}
                  alt={article.markdownRemark.frontmatter.title}
                />
              </ArticleIllustration>
            </ArticleIllustrationWrapper>
            {renderAst(article.markdownRemark.htmlAst)}
          </>
        </ArticleContentContainer>
      </ArticleContentWrapper>
      <ArticleLineAboveRecommendedArticles />
      <ArticleCTA />
      <ArticleNewsletter />
      <ArticleLineAboveRecommendedArticles />
      <FeaturedArticlesSection allArticlePreview={allArticlePreview} article={article} />
    </ArticleWrapper>
  );
};

export default ArticleContent;

export const query = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            description
            date
            author
            tags
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            authorImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          id
          html
          htmlAst
          fields {
            slug
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;
