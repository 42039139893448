import React from "react";

import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import HeaderGroup, {
  type HeaderGroupProps
} from "components/common/headers/HeaderGroup/HeaderGroup";
import {
  ArticleCtaButtonWrapper,
  ArticleCtaContentWrapper,
  ArticleCtaDescription,
  ArticleCtaImage,
  ArticleCtaImageWrapper,
  ArticleCtaWrapper
} from "components/pages/article/ArticleCTA/ArticleCTA.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";
import Translate from "utils/translation/translation";

interface ArticleCTAProps {
  headerData?: HeaderGroupProps;
  description?: string;
  descriptionTwo?: string;
}

const ArticleCTA = ({
  headerData = defaultHeaderData,
  description = defaultDescription
}: ArticleCTAProps) => {
  const [imageDatabase] = useImageDatabaseContext();
  const image = imageDatabase["ArticleCTA"].childImageSharp.gatsbyImageData;

  return (
    <ArticleCtaWrapper>
      <ArticleCtaContentWrapper>
        <HeaderGroup {...headerData} />
        <ArticleCtaDescription>{description}</ArticleCtaDescription>
        <ArticleCtaButtonWrapper>
          <CtaButton
            text={"Call_To_Action_Article_Button_Default"}
            color="black"
            variant="contact-page-cta-button-font"
            wide
          />
        </ArticleCtaButtonWrapper>
      </ArticleCtaContentWrapper>
      <ArticleCtaImageWrapper>
        <ArticleCtaImage image={image} alt="Article CTA" />
      </ArticleCtaImageWrapper>
    </ArticleCtaWrapper>
  );
};

const defaultHeaderData: HeaderGroupProps = {
  headers: [
    {
      text: "Call_To_Action_Article_Header_Default",
      variant: "CallToActionArticleHeader",
      fontVariant: "call-to-action-article-header",
      id: "call-to-action-article-header",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 }
    }
  ]
};

const defaultDescription = Translate("Call_To_Action_Article_Description_Default");

export default ArticleCTA;
