import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ArticleTableOfContentFont,
  ArticleTableOfContentTitleFont
} from "styled/common/font-styles/ArticleFonts";
import { SizeFillSpaceMixin } from "styled/common/Utils";
import styled from "styled-components";

export const ArticleTableOfContentWrapper = styled.div`
  transform: translateZ(11px);
  position: fixed;

  ${CalculateFromHeight("margin-top", { desktop: 5 })}
  ${CalculateFromHeight("top", { desktop: 1 })}
  ${CalculateFromWidth("width", { desktop: 19.25 })}
  ${CalculateFromWidth("left", { desktop: 2.8 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
  }
  pointer-events: none;
`;

export const ArticleTableOfContentBody = styled.div`
  ${CalculateFromHeight("margin-top", { desktop: 11 })}
  ${CalculateFromHeight("margin-bottom", { desktop: 11 })}
  position: relative;
  display: flex;
`;

export const ArticleTableOfContentContainer = styled.div`
  ${ArticleTableOfContentFont}
  margin-top: auto;
  margin-bottom: auto;
  top: 10%;
  position: relative;
  pointer-events: all;
  background-color: ${({ theme: { colors } }) => colors.white};
  z-index: 1;
  & a {
    color: inherit;
  }
`;

export const ArticleTableOfContentTitle = styled.span`
  ${ArticleTableOfContentTitleFont}
`;

export const ArticleTableOfContentText = styled.a`
  cursor: pointer;
  display: flex;
  ${CalculateFromHeight("margin-top", { desktop: 2 })}
`;

export const ArticleTableOfContentEntry = styled.div`
  color: inherit;
  left: 0%;
  right: 0%;
  ${SizeFillSpaceMixin}
  border-bottom: 1px solid #e5e5e5;
  transition: all 0.5s;
  display: inline-flex;
  ${CalculateFromHeight("padding-bottom", { desktop: 1.5 })}
  ${CalculateFromWidth("margin-right", { desktop: 0.5 })}

  &.active {
    color: #6729e9;
    left: 0%;
    right: 0%;
    ${SizeFillSpaceMixin}
    border: 0 none;
    border-bottom: 1px solid;
    background-color: transparent;
    -webkit-border-image: -webkit-linear-gradient(left, #ff00cc -1.07%, #6729e9 100%) 5;
    -moz-border-image: -moz-linear-gradient(to right, #ff00cc -1.07%, #6729e9 100%) 5;
    -o-border-image: -o-linear-gradient(to right, #ff00cc -1.07%, #6729e9 100%) 5;
    border-image: linear-gradient(to right, #ff00cc -1.07%, #6729e9 100%) 5;
    ${CalculateFromHeight("padding-bottom", { desktop: 1.5 })};
    ${CalculateFromWidth("margin-right", { desktop: 0.5 })};
    transition: all 0.5s;
    display: inline-flex;
  }
`;

export const ArticleTableOfContentIndex = styled.span`
  height: 100%;
  ${CalculateFromHeight("margin-right", { desktop: 2 })};
`;
