import { SocialIconImage } from "components/layout/SocialIcons/SocialIcons.styled";
import { ArticlePreviewTagsWrapper } from "components/pages/blog-common/ArticlePreviewInformation/ArticlePreviewInformation.styled";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  CalculateFontSize,
  CalculateFromHeight,
  CalculateFromWidth
} from "styled/base/SizeFunctions";
import { ArticleDateTimeInfoSectionFont } from "styled/common/font-styles/ArticleFonts";
import { NonSelectable } from "styled/common/Utils";
import styled from "styled-components";

export const ArticleAuthorWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-left: auto;
`;

export const ArticleAuthorInfo = styled.div`
  width: 100%;
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: #e5e5e5;
  display: flex;
  ${CalculateFromHeight("border-width", { mobile: 0.16, tablet: 0.16, desktop: 0.16 })}
  ${CalculateFromHeight("height", { mobile: 24.5, tablet: 10.4, desktop: 8.6 })}
`;

export const ArticleAuthorImageWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1 / span 2;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 12 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 15 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 18 / span 2;
  }
  ${CalculateFromWidth("margin-right", { mobile: 4.3, tablet: 3.2, desktop: 1.5 })}
`;
export const ArticleAuthorPhotoFace = styled(GatsbyImage)`
  ${NonSelectable}
  border-radius: 50%;
  ${CalculateFromWidth("min-width", { mobile: 10.5, tablet: 6.3, desktop: 3 })};
  ${CalculateFromWidth("max-width", { mobile: 10.5, tablet: 6.3, desktop: 3 })};
  ${CalculateFromWidth("max-height", { mobile: 10.5, tablet: 6.3, desktop: 3 })};
`;

export const ArticleAuthorName = styled.span`
  font-weight: 600;
  display: flex;
  align-items: center;

  ${CalculateFontSize("font-size", { mobile: 1.5, tablet: 1.2, desktop: 1 })}
`;

export const ArticleAuthorSocialIcons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  order: 2;
  ${SocialIconImage} {
    ${CalculateFromWidth("width", { mobile: 4.1, tablet: 2.1, desktop: 1 })};
    ${CalculateFromWidth("height", { mobile: 4.1, tablet: 2.1, desktop: 1 })};
    ${CalculateFromWidth("margin-left", { mobile: 8.3, tablet: 6.3, desktop: 1 })};
  }
`;
export const ArticleTagsDateTimeInfo = styled.div`
  clear: both;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: inline-block;
  }
  ${CalculateFromHeight("margin-top", { mobile: 1.5, tablet: 1.5, desktop: 1.5 })}
`;

export const ArticlePreviewTagsInfoSection = styled(ArticlePreviewTagsWrapper)`
  width: auto;
  max-width: 50%;
  margin: unset;
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    height: 80%;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: 0;
  }
`;
export const ArticleDateTimeInfoSection = styled.div`
  ${ArticleDateTimeInfoSectionFont}
  display: inline-flex;
  ${CalculateFromWidth("margin-top", { mobile: 1, tablet: 1, desktop: 1 })};
  ${CalculateFromWidth("margin-left", { mobile: 2 })};
`;
export const ArticleReadingTimeIcon = styled.img`
  ${NonSelectable}
  margin-bottom: auto;
  ${CalculateFromWidth("width", { mobile: 3, tablet: 1.75, desktop: 1.125 })};
  ${CalculateFromWidth("height", { mobile: 3, tablet: 1.75, desktop: 1.125 })};
  ${CalculateFromHeight("margin-top", { mobile: 2, tablet: 0.1, desktop: 0.1 })};
  ${CalculateFromWidth("margin-right", { mobile: 1.5, tablet: 1.75, desktop: 1.5 })};
`;
export const ArticleDateInfo = styled.p`
  ${CalculateFromHeight("margin-left", { mobile: 6, tablet: 6, desktop: 6 })}
`;
