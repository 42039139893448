import { postPromise } from "network/base-promises";

export interface CheckboxesState {
  news: boolean;
  jobs: boolean;
}

export interface SendNewsletterRequest {
  email: string;
  name?: string;
  checkboxes?: CheckboxesState;
}

export interface SendNewsletterResponse {
  code: string;
  status: number;
}

export const sendNewsletter = async ({
  email,
  name = "",
  checkboxes = defaultCheckboxesState
}: SendNewsletterRequest): Promise<SendNewsletterResponse> => {
  const url = process.env.SERVER_URL
    ? process.env.SERVER_URL
    : "https://server-dev.dreamstormstudios.com";
  const path = `${url}/api/signup`;

  const payload = { email, name, checkboxes };

  return postPromise<SendNewsletterRequest, SendNewsletterResponse>(path, payload);
};

const defaultCheckboxesState = {
  news: true,
  jobs: false
};
