import React, { FormEvent } from "react";
import { useEffect, useRef, useState } from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import TrackedInput from "components/common/analytics/TrackedInput";
import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import HeaderGroup, {
  type HeaderGroupProps
} from "components/common/headers/HeaderGroup/HeaderGroup";
import {
  ArticleNewsletterButton,
  ArticleNewsletterDescription,
  ArticleNewsletterForm,
  ArticleNewsletterInformation,
  ArticleNewsletterInput,
  ArticleNewsletterWrapper
} from "components/pages/article/ArticlesNewsletter/ArticleNewsletter.styled";
import { sendNewsletter } from "network/send-newsletter";
import { emailRegex } from "utils/regularExpressions";
import Translate from "utils/translation/translation";

export const ArticleNewsletter = () => {
  const [email, setEmail] = useState("");
  const [hasSentForm, setHasSentForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newsletterMessage, setNewsletterMessage] = useState({ content: "", color: "" });
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const isEmailValid = emailRegex.test(email);

    if ((email === "" || isEmailValid) && !hasSentForm) {
      setNewsletterMessage({ content: "", color: "" });
    } else if (!isEmailValid && !hasSentForm) {
      setNewsletterMessage({ content: "Blog_Newsletter_Invalid_Email", color: "red" });
    }
  }, [email]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setNewsletterMessage({ content: "", color: "" });

    const isEmailValid = emailRegex.test(email);

    if (!isEmailValid) {
      setNewsletterMessage({ content: "Blog_Newsletter_Invalid_Email", color: "red" });

      return;
    }

    setIsLoading(true);

    try {
      const response = await sendNewsletter({ email });

      setHasSentForm(true);

      const isStatusCodeValid = response.code === "OK";

      if (isStatusCodeValid) {
        setEmail("");

        if (input.current) {
          input.current.value = "";
        }
      }

      setNewsletterMessage({
        content: isStatusCodeValid ? "Blog_Newsletter_Success" : "Blog_Newsletter_Failure",
        color: isStatusCodeValid ? "black" : "red"
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setNewsletterMessage({ content: "Blog_Newsletter_Failure", color: "red" });
      setIsLoading(false);
    }
  };

  return (
    <ArticleNewsletterWrapper>
      <HeaderGroup {...headerData} />
      <ArticleNewsletterDescription>
        {Translate("Blog_Newsletter_Description")}
      </ArticleNewsletterDescription>
      <ArticleNewsletterForm onSubmit={onSubmit} name="Blog newsletter form">
        <TrackedInput category="newsletter" action="newsletter-email">
          <ArticleNewsletterInput
            name="email"
            placeholder={Translate("Blog_Newsletter_Input")}
            disabled={isLoading}
            onChange={e => {
              setHasSentForm(false);
              setEmail(e.target.value);
            }}
            ref={input}
          />
        </TrackedInput>
        <ArticleNewsletterInformation style={{ color: newsletterMessage.color }}>
          {Translate(newsletterMessage.content)}
        </ArticleNewsletterInformation>
        <ArticleNewsletterButton>
          <TrackedButton category="newsletter" action="newsletter-submit">
            <CtaButton
              text="Blog_Newsletter_Button"
              type="submit"
              color="black"
              variant="contact-page-cta-button-font"
            />
          </TrackedButton>
        </ArticleNewsletterButton>
      </ArticleNewsletterForm>
    </ArticleNewsletterWrapper>
  );
};

export default ArticleNewsletter;

const headerData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: 1,
  headers: [
    {
      text: "Blog_Newsletter_Header_Join",
      variant: "ArticleNewsletterHeaderJoin",
      id: "newsletter-header-join",
      horizontalScrollSpeed: -0.05
    },
    {
      text: "Blog_Newsletter_Header_Newsletter",
      variant: "ArticleNewsletterHeaderNewsletter",
      id: "newsletter-header-newsletter",
      horizontalScrollSpeed: 0.05
    }
  ],
  disableDefaultStyles: true
};
