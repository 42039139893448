import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { ArticlePreviewSectionTitleFont } from "styled/common/font-styles/ArticleFonts";
import styled from "styled-components";

export const ArticlePreviewSectionWrapper = styled.div`
  ${CalculateFromHeight("margin-left", { mobile: 3, tablet: 2 })};
  ${CalculateFromHeight("margin-right", { mobile: 3, tablet: 2 })};
  z-index: 1;
`;

export const ArticlePreviewSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${CalculateFromWidth("margin-bottom", { mobile: 3, tablet: 3, desktop: 3 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: block;
  }
`;

export const ArticlePreviewSectionTitle = styled.h2`
  ${ArticlePreviewSectionTitleFont}
  color: black;
  transform: scaleZ(0.1);
  width: fit-content;
`;
