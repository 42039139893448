import React, { RefObject, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import {
  ArticleTableOfContentBody,
  ArticleTableOfContentContainer,
  ArticleTableOfContentEntry,
  ArticleTableOfContentIndex,
  ArticleTableOfContentText,
  ArticleTableOfContentTitle,
  ArticleTableOfContentWrapper
} from "components/pages/article/ArticleTableOfContent/ArticleTableOfContent.styled";
import { registerScrollCallback, useScrollContext } from "contexts/ScrollContext";
import LocomotiveScroll from "locomotive-scroll";
import { isScrollUndefined } from "utils/window-utils";

interface ArticleTableOfContentProps {
  articleContentRefProp: RefObject<HTMLDivElement>;
}

export const ArticleTableOfContent = ({ articleContentRefProp }: ArticleTableOfContentProps) => {
  const [articleContentHeight, setArticleContentHeight] = useState(0);
  const [articleHeaders, setArticleHeaders] = useState<HTMLHeadingElement[]>([]);
  const [renderedHeaderList, setRenderedHeaderList] = useState<JSX.Element[]>([]);
  const [, dispatch] = useScrollContext();

  useEffect(() => {
    const articleHeaders = Array.from(articleContentRefProp.current!.getElementsByTagName("h2"));

    setArticleHeaders(articleHeaders);
    setRenderedHeaderList(renderHeaderList(articleHeaders, scrollToHeader));

    const resizeObserver = () =>
      setArticleContentHeight(articleContentRefProp.current?.getBoundingClientRect().height ?? 0);

    window.addEventListener("resize", resizeObserver);

    return () => {
      window.removeEventListener("resize", resizeObserver);
    };
  }, []);

  const scrollToHeader = (header: HTMLHeadingElement) => {
    if (isScrollUndefined()) {
      return;
    }

    const locomotiveScroll = (window.scroll as any).scroll as LocomotiveScroll;
    const offset = header.offsetTop + 3 * header.offsetHeight;

    locomotiveScroll.scrollTo(offset);
  };

  const getClosestHeader = () => {
    const targetItem = { item: articleHeaders[0], distance: document.body.scrollHeight };

    articleHeaders.forEach(item => {
      const boundingRect = item.getBoundingClientRect();
      const currentDistance = Math.abs(boundingRect.top);

      if (currentDistance < targetItem.distance) {
        targetItem.item = item;
        targetItem.distance = currentDistance;
      }
    });

    return targetItem.item;
  };

  const updateTableColors = () => {
    const currentHeader = getClosestHeader();
    const id = currentHeader.getAttribute("id");

    articleHeaders.forEach(header => {
      const headerById = document.querySelector(`span[id="ToC-${header.getAttribute("id")}"]`);

      if (headerById) {
        headerById.parentElement!.classList.remove("active");
      }
    });

    const tableOfContentElement = document.querySelector(`span[id="ToC-${id}"]`);

    if (tableOfContentElement) {
      tableOfContentElement.parentElement!.classList.add("active");
    }
  };

  useEffect(() => {
    if (articleHeaders.length > 0) {
      dispatch(
        registerScrollCallback({
          scrollPositionTrigger: 0,
          methodToInvoke: updateTableColors,
          isOneShot: false
        })
      );
      updateTableColors();
    }
  }, [articleHeaders]);

  return (
    <ArticleTableOfContentWrapper
      id="article-table-of-content-wrapper"
      style={{ height: `${articleContentHeight}px` }}
    >
      <LocomotiveElement
        scrollSticky="center"
        scrollDelay={1}
        scrollTarget="#article-table-of-content-wrapper"
        shouldAnimate={!isMobile}
      >
        <ArticleTableOfContentBody>
          <ArticleTableOfContentContainer>
            <ArticleTableOfContentTitle>Table of content:</ArticleTableOfContentTitle>
            {renderedHeaderList}
          </ArticleTableOfContentContainer>
        </ArticleTableOfContentBody>
      </LocomotiveElement>
    </ArticleTableOfContentWrapper>
  );
};

export default ArticleTableOfContent;

const renderHeaderList = (
  articleHeaders: HTMLHeadingElement[],
  scrollToHeader: (header: HTMLHeadingElement) => void
) => {
  return articleHeaders.map((header, index) => (
    <ArticleTableOfContentText
      key={index}
      onClick={() => scrollToHeader(header)}
      id={`ToC-${header.getAttribute("id")}`}
    >
      <ArticleTableOfContentEntry>
        <ArticleTableOfContentIndex>
          {(index + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}
          .
        </ArticleTableOfContentIndex>
        <span id={`ToC-${header.getAttribute("id")}`}>{header.innerText}</span>{" "}
      </ArticleTableOfContentEntry>
    </ArticleTableOfContentText>
  ));
};
