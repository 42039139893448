import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";

import useNativeLazyLoading from "@charlietango/use-native-lazy-loading";

interface ArticleCustomImageProps {
  src: string;
  srcSet: string;
  sizes: string;
  aspectRatio: number;
  alt: string;
  base64: string;
}

export const ArticleCustomImage = ({
  srcSet,
  sizes,
  aspectRatio,
  alt,
  base64
}: ArticleCustomImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const supportsLazyLoading = useNativeLazyLoading();
  const [wrapperRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px"
  });

  return (
    <div
      ref={supportsLazyLoading === false ? wrapperRef : undefined}
      className="article-image-wrapper"
      style={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#ffffff"
      }}
    >
      <div
        style={{
          width: "100%",
          paddingBottom: `${100 / aspectRatio}%`
        }}
      />
      <div
        title={alt}
        style={{
          position: "absolute",
          inset: "0px",
          opacity: "0",
          transitionDelay: "500ms"
        }}
      />
      <img
        src={base64}
        title={alt}
        alt={alt}
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center center",
          filter: "blur(50px)", // for a nicer looking blur
          transform: "scale(1)" // this is needed so Safari keeps sharp edges
        }}
        className="non-selectable"
      />
      {inView || supportsLazyLoading ? (
        <picture>
          <source srcSet={srcSet} />
          <img
            srcSet={srcSet}
            src={srcSet.split(",")[0].split(" ")[0]} // get first (lowest quality) entry in srcSet
            sizes={sizes}
            title={alt}
            alt={alt}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center"
            }}
            loading="lazy"
            className="non-selectable"
          />
        </picture>
      ) : null}
      <noscript>
        <picture>
          <source srcSet={srcSet} sizes={sizes} />
          <img
            srcSet={srcSet}
            src={srcSet.split(",")[0].split(" ")[0]}
            sizes={sizes}
            title={alt}
            alt={alt}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
              opacity: "0",
              transition: "opacity .5s easeOutCirc" // can change timing here
            }}
            loading="lazy"
            ref={imgRef}
            onLoad={() => (imgRef.current!.style.opacity = "1")}
            className="non-selectable"
          />
        </picture>
      </noscript>
    </div>
  );
};

export default ArticleCustomImage;
