import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import Layout from "components/layout/Layout/Layout";
import WhiteBackground from "components/layout/WhiteBackground/WhiteBackground";
import ArticleContent from "components/pages/article/ArticleContent/ArticleContent";
import ArticleTableOfContent from "components/pages/article/ArticleTableOfContent/ArticleTableOfContent";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import { graphql } from "gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { GatsbySeo, type GatsbySeoProps } from "gatsby-plugin-next-seo";

export interface ArticleData {
  id?: string;
  fields: {
    readingTime: {
      text: string;
    };
    slug: string;
  };
  frontmatter: {
    author: string;
    authorImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    date: string;
    description: string;
    image: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    tags: string[];
    title: string;
  };
  html: string;
  htmlAst: {
    children: [
      {
        children: [
          {
            type: string;
            tagName: string;
            properties: {
              className: string[];
            };
            children: [
              {
                type: string;
                value: string;
              }
            ];
          }
        ];
        type: string;
        tagName: string;
        properties: {
          className: string[];
        };
      }
    ];
    data: {
      quirksMode: boolean;
    };
    type: string;
  };
}

interface ArticleProps {
  location: Location;
  data: {
    markdownRemark: ArticleData;
  };
}

const Article = ({ location, data }: ArticleProps) => {
  const articleContentRef = useRef<HTMLDivElement>(null);
  const SEO: GatsbySeoProps = {
    title: "DreamStorm Studios | Blog | " + data.markdownRemark.frontmatter.title,
    description: data.markdownRemark.frontmatter.description,
    canonical: "https://dreamstormstudios.com/" + data.markdownRemark.fields.slug,
    openGraph: {
      url: "https://dreamstormstudios.com/" + data.markdownRemark.fields.slug,
      title: "DreamStorm Studios | Blog | " + data.markdownRemark.frontmatter.title,
      description: data.markdownRemark.frontmatter.description,
      site_name: "Article",
      images: [
        {
          url:
            "https://dreamstormstudios.com/" +
            data.markdownRemark.frontmatter.image.childImageSharp.gatsbyImageData.images.fallback!
              .src,
          alt: "DreamStorm Studios | Brought together by passion",
          width: 1200,
          height: 630
        }
      ]
    }
  };

  return (
    <>
      <Helmet defer={false}>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GatsbySeo {...SEO} />
      <Layout
        location={location}
        asideChildren={<ArticleTableOfContent articleContentRefProp={articleContentRef} />}
      >
        <MainCloud color="VioletBlack" variant="ArticleCloudWrapper" scrollSpeed={0.5} />
        <WhiteBackground type="article" />
        <ArticleContent article={data} articleContentRef={articleContentRef} />
      </Layout>
    </>
  );
};

export default Article;

export const query = graphql`
  query ($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      html
      frontmatter {
        title
        description
        author
        date
        tags
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        authorImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`;
