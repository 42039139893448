import { CalculateFromHeight } from "styled/base/SizeFunctions";
import styled from "styled-components";

export const FeaturedArticlesSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: inline-block;
  }
  ${CalculateFromHeight("margin-bottom", { mobile: 13, tablet: 13, desktop: 13 })}
`;
