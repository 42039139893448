import * as React from "react";

import SocialIcon from "components/layout/SocialIcons/SocialIcon";
import {
  ArticleAuthorImageWrapper,
  ArticleAuthorInfo,
  ArticleAuthorName,
  ArticleAuthorPhotoFace,
  ArticleAuthorSocialIcons,
  ArticleAuthorWrapper,
  ArticleDateInfo,
  ArticleDateTimeInfoSection,
  ArticlePreviewTagsInfoSection,
  ArticleReadingTimeIcon,
  ArticleTagsDateTimeInfo
} from "components/pages/article/ArticleAuthor/ArticleAuthor.styled";
import TagsRectangularButton from "components/pages/blog-common/TagsRectangularButton/TagsRectangularButton";
import FacebookBlack from "media/images/icons/contact/facebook-black.svg";
import LinkedinBlack from "media/images/icons/contact/linkedin-black.svg";
import TwitterBlack from "media/images/icons/contact/twitter-black.svg";
import ClockIcon from "media/images/icons/other/article-clock-icon.svg";
import type { ArticleData } from "pages/blog/article";

interface ArticleAuthorProps {
  markdown: ArticleData;
}

export const ArticleAuthor = ({ markdown }: ArticleAuthorProps) => {
  return (
    <ArticleAuthorWrapper>
      <ArticleAuthorInfo>
        <ArticleAuthorImageWrapper>
          <ArticleAuthorPhotoFace
            image={markdown.frontmatter.authorImage.childImageSharp.gatsbyImageData}
            draggable={false}
            alt="Photo face"
          />
        </ArticleAuthorImageWrapper>
        <ArticleAuthorName>{markdown.frontmatter.author}</ArticleAuthorName>
        <ArticleAuthorSocialIcons>
          <SocialIcon
            variant="article-author-social-icon-wrapper"
            image={FacebookBlack}
            url="https://www.facebook.com/DreamStormStudios/"
            name="Facebook"
          />
          <SocialIcon
            variant="article-author-social-icon-wrapper"
            image={TwitterBlack}
            url="https://twitter.com/TheDreamStorm"
            name="Twitter"
          />
          <SocialIcon
            variant="article-author-social-icon-wrapper"
            image={LinkedinBlack}
            url="https://www.linkedin.com/company/dreamstormstudios"
            name="LinkedIn"
          />
        </ArticleAuthorSocialIcons>
      </ArticleAuthorInfo>
      <ArticleTagsDateTimeInfo>
        <ArticlePreviewTagsInfoSection>
          {markdown.frontmatter.tags.map((tag, i) => (
            <TagsRectangularButton key={i} text={tag} isArticle disableButtons />
          ))}
        </ArticlePreviewTagsInfoSection>
        <ArticleDateTimeInfoSection>
          <ArticleReadingTimeIcon src={ClockIcon} alt="Reading Time" />
          <p> {markdown.fields.readingTime.text} </p>
          <ArticleDateInfo>
            <time>{markdown.frontmatter.date}</time>
          </ArticleDateInfo>
        </ArticleDateTimeInfoSection>
      </ArticleTagsDateTimeInfo>
    </ArticleAuthorWrapper>
  );
};

export default ArticleAuthor;
