import * as React from "react";

import { FeaturedArticlesSection } from "components/pages/article/ArticlesFeatured/ArticlesFeatured.styled";
import type { ArticlePreviewData } from "components/pages/blog-common/ArticlePreview/ArticlePreview";
import ArticlePreview from "components/pages/blog-common/ArticlePreview/ArticlePreview";
import { GetFeaturedArticles } from "utils/blog-helpers";

interface FeaturedArticlesProps {
  articleList: ArticlePreviewData[];
  tagsList: string[];
  articleTitle: string;
}

export const FeaturedArticles = ({
  articleList,
  tagsList,
  articleTitle
}: FeaturedArticlesProps) => {
  return (
    <FeaturedArticlesSection>
      {GetFeaturedArticles(articleList, tagsList, articleTitle).map(article => (
        <ArticlePreview key={article[1].node.id} articleDetails={article[1]} isRecommendedArticle />
      ))}
    </FeaturedArticlesSection>
  );
};

export default FeaturedArticles;
